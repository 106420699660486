import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const GISMapping = () => (
  <Layout>
    <SEO title="GIS Mapping" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-blue-main">GIS Mapping</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              We provide high-level expertise, professionalism, and reliability
              in the field of GIS Mapping for the oil and gas industry. Pacific
              Coast also serves the energy industry and our team is always ready
              to rise to the challenges unique to these industries.
            </p>
            <p>
              We handle downstream, midstream, and upstream mapping projects and
              will make sure that each project proceeds in a data-driven and
              results-oriented manner.
            </p>
            <p>
              Whether you are interested in a simple plat map or organize an
              entire map of various prospective tracts, we can provide you with
              capable assistance. All of our solutions are designed to make it
              easier to achieve development plans and coordinate leasing
              arrangements.
            </p>
            <p>
              Expect dynamic and visually stunning maps complete with unit
              boundaries and various vantage points. Enjoy an improved and
              effective GIS mapping service from start to finish.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">
              Amazing Maps for Your Gas, Oil,
              <br />
              or Energy Holdings
            </h2>
          </div>
          <div className="col-lg-8">
            <p>
              Pacific Coast Land Solutions will provide your business with GIS
              maps that you can rely on in terms of tracking and understanding
              your holdings.
            </p>
            <p className="font-weight-bold">
              We offer customized maps, administrative maps, and more.
            </p>
          </div>
          <div className="col-lg-10 mx-auto my-3">
            <ul style={{ columns: 2 }}>
              <h3 className="h4">Administrative Maps</h3>
              <ul className="ml-4 mt-3">
                <li>Lease Expiration Maps</li>
                <li>Payment and Special Obligation Maps</li>
                <li>Parcel and Ownership Layers</li>
              </ul>
              <h3 className="h4 mt-3">Custom Maps</h3>
              <ul className="ml-4 mt-3">
                <li>Leasehold Maps</li>
                <li>Presentation Maps</li>
                <li>Plat Maps</li>
              </ul>
              <h3 className="h4 mt-3">GIS Setup and Maintenance</h3>
              <ul className="ml-4 mt-3">
                <li>Digital Maps in various formats</li>
                <li>Shapefile and Geodatabase Creation</li>
                <li>Polygon import & linking</li>
              </ul>
              <h3 className="h4 mt-3">Data Layers</h3>
              <ul className="ml-4 mt-3">
                <li>Production Maps and Layers</li>
                <li>Well & Drilling Data</li>
                <li>Geology and Depth Layers</li>
              </ul>
            </ul>
          </div>
          <div className="col-lg-12">
            <p>
              To know more about these services and how we can help you take
              advantage of the best GIS mapping technologies, email us at
              <a
                href="mailto:info@pacificcoastls.com"
                className="text-blue-main mx-1"
              >
                info@pacificcoastls.com
              </a>
              or call us:
              <a href="tel:720.234.1155" className="text-blue-main mx-1">
                720.234.1155
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default GISMapping
